<template>
	<div
		class="wrap__content"
		v-bind:class="{
			'wrap__content--preloader':
				!applicationsUser || !applicationsPond || !applicationsRegion || !applicationsFish,
		}"
	>
		<v-preloader
			v-if="!applicationsUser || !applicationsPond || !applicationsRegion || !applicationsFish"
			:message="errorMessage"
		></v-preloader>

		<div
			class="container"
			v-if="applicationsUser && applicationsPond && applicationsRegion && applicationsFish"
		>
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['MyProfile']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title">
									<div class="content__page--back">
										<a @click="$router.go(-1)">
											<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13.125 5.25l-5.25 5.25 5.25 5.25" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
										</a>
									</div>
									<div class="content__title--element">
										<div class="content__title--text">{{ $t('labels.subject_profile') }}</div>
									</div>
								</div>
							</div>
							<div class="card__content--body">
								<div class="user-profile-card__top">
									<div class="row">
										<div class="col-xl-6">
											<div class="user-profile-card__line">
												<div class="user-profile-card__line--left">
													<strong>{{ $t('headers.fio') }}</strong>
												</div>
												<div class="user-profile-card__line--right">
													{{ applicationsUser.last_name }} {{ applicationsUser.first_name }}
													{{ applicationsUser.middle_name }}
												</div>
											</div>
										</div>
										<div class="col-xl-6" v-if="applicationsUser.name_company">
											<div class="user-profile-card__line">
												<div class="user-profile-card__line--left">
													<strong>{{ $t('headers.company_name') }}</strong>
												</div>
												<div class="user-profile-card__line--right">
													{{ applicationsUser.name_company }}
												</div>
											</div>
										</div>

										<div class="col-xl-6" v-if="applicationsUser.created_at">
											<div class="user-profile-card__line">
												<div class="user-profile-card__line--left">
													<strong>{{ $t('headers.registration_date') }}</strong>
												</div>
												<div class="user-profile-card__line--right">
													{{ applicationsUser.created_at | formatDate }}
												</div>
											</div>
										</div>

										<div class="col-xl-6" v-if="applicationsUser.iin_bin">
											<div class="user-profile-card__line">
												<div class="user-profile-card__line--left">
													<strong>{{ $t('headers.iin_bin') }}</strong>
												</div>
												<div class="user-profile-card__line--right">
													{{ applicationsUser.iin_bin }}
												</div>
											</div>
										</div>

										<div
											class="col-xl-6"
											v-if="applicationsUser.user_info && applicationsUser.user_info.obj"
										>
											<div class="user-profile-card__line">
												<div class="user-profile-card__line--left">
													<strong>{{ $t('headers.form') }}</strong>
												</div>
												<div class="user-profile-card__line--right">
													<span v-if="$i18n.locale == 'ru'">
														{{ applicationsUser.user_info.obj.OrgForm['NameRu'] }}
													</span>
													<span v-if="$i18n.locale == 'kz'">
														{{ applicationsUser.user_info.obj.OrgForm['NameKz'] }}
													</span>
													<span v-if="$i18n.locale == 'en'">
														{{ applicationsUser.user_info.obj.OrgForm['NameRu'] }}
													</span>
												</div>
											</div>
										</div>

										<div class="col-xl-6" v-if="applicationsUser.email">
											<div class="user-profile-card__line">
												<div class="user-profile-card__line--left"><strong>Email</strong></div>
												<div class="user-profile-card__line--right">
													{{ applicationsUser.email }}
												</div>
											</div>
										</div>

										<div
											class="col-xl-6"
											v-if="applicationsQuota && Object.keys(applicationsQuota).length != 0"
										>
											<div class="user-profile-card__line">
												<div class="user-profile-card__line--left">
													<strong>{{ $t('headers.quota_region') }}</strong>
												</div>
												<div class="user-profile-card__line--right">
													<span>
														{{ regionList(applicationsQuota.region_id) }}
													</span>
												</div>
											</div>
										</div>
										<div
											class="col-xl-6"
											v-if="applicationsQuota && Object.keys(applicationsQuota).length != 0"
										>
											<div class="user-profile-card__line">
												<div class="user-profile-card__line--left">
													<strong>{{ $t('headers.reservoirs_quotas') }}</strong>
												</div>
												<div class="user-profile-card__line--right">
													<span v-for="(item, itemInd) in applicationsQuota.items" :key="itemInd">
														{{ pondList(item.pond_id) }}
													</span>
												</div>
											</div>
										</div>

										<div
											class="col-xl-6"
											v-if="applicationsUser.user_info && applicationsUser.user_info.obj"
										>
											<div class="user-profile-card__line">
												<div class="user-profile-card__line--left">
													<strong>{{ $t('headers.oced') }}</strong>
												</div>
												<div class="user-profile-card__line--right">
													{{ applicationsUser.user_info.obj.Activity.OKED }}
												</div>
											</div>
										</div>
										<div
											class="col-xl-6"
											v-if="applicationsUser.user_info && applicationsUser.user_info.obj"
										>
											<div class="user-profile-card__line">
												<div class="user-profile-card__line--left">
													<strong>{{ $t('headers.kato') }}</strong>
												</div>
												<div class="user-profile-card__line--right">
													{{ applicationsUser.user_info.obj.Address.KATO }}
												</div>
											</div>
										</div>
										<div
											class="col-xl-6"
											v-if="applicationsUser.user_info && applicationsUser.user_info.obj"
										>
											<div class="user-profile-card__line">
												<div class="user-profile-card__line--left">
													<strong>{{ $t('headers.status') }}</strong>
												</div>
												<div class="user-profile-card__line--right">
													<span v-if="$i18n.locale == 'ru'">
														{{ applicationsUser.user_info.obj.RegStatus['NameRu'] }}
													</span>
													<span v-if="$i18n.locale == 'kz'">
														{{ applicationsUser.user_info.obj.RegStatus['NameKz'] }}
													</span>
													<span v-if="$i18n.locale == 'en'">
														{{ applicationsUser.user_info.obj.RegStatus['NameRu'] }}
													</span>
												</div>
											</div>
										</div>
										<div
											class="col-xl-6"
											v-if="applicationsUser.user_info && applicationsUser.user_info.obj"
										>
											<div class="user-profile-card__line">
												<div class="user-profile-card__line--left">
													<strong>{{ $t('headers.address_registration') }}</strong>
												</div>
												<div class="user-profile-card__line--right">
													<span v-if="$i18n.locale == 'ru'">
														{{ applicationsUser.user_info.obj.Address['DistrictRu'] }}
														{{ applicationsUser.user_info.obj.Address['RegionRu'] }}
														{{ applicationsUser.user_info.obj.Address['StreetRu'] }}
													</span>
													<span v-if="$i18n.locale == 'kz'">
														{{ applicationsUser.user_info.obj.Address['DistrictKz'] }}
														{{ applicationsUser.user_info.obj.Address['RegionKz'] }}
														{{ applicationsUser.user_info.obj.Address['StreetKz'] }}
													</span>
													<span v-if="$i18n.locale == 'en'">
														{{ applicationsUser.user_info.obj.Address['DistrictRu'] }}
														{{ applicationsUser.user_info.obj.Address['RegionRu'] }}
														{{ applicationsUser.user_info.obj.Address['StreetRu'] }}
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="user-profile-card__body">
									<div class="user-profile__tabs">
										<ul>
											<li
												v-bind:class="{ active: tabElement == 'tabElement1', disabled: true }"
												v-if="applicationsQuota"
											>
												<a @click="tabElement = 'tabElement1'">{{ $t('headers.quotas') }}</a>
											</li>
											<li v-bind:class="{ active: tabElement == 'tabElement2' }">
												<a @click="tabElement = 'tabElement2'">{{ $t('headers.permissions') }}</a>
											</li>
											<li v-bind:class="{ active: tabElement == 'tabElement3' }">
												<a @click="tabElement = 'tabElement3'">{{ $t('headers.journals') }}</a>
											</li>
											<li v-bind:class="{ active: tabElement == 'tabElement4', disabled: true }">
												<a @click="tabElement = 'tabElement4'">{{ $t('headers.handbooks') }}</a>
											</li>
											<li v-bind:class="{ active: tabElement == 'tabElement5', disabled: true }">
												<a @click="tabElement = 'tabElement5'">{{ $t('sidebar.fine') }}</a>
											</li>
										</ul>
									</div>

									<div class="user-profile__tabs--content">
										<div
											class="user-profile__tab--item"
											v-if="tabElement == 'tabElement1' && applicationsQuota"
										>
											<div class="table__block--content user-profile__block--content">
												<v-data-table
													:headers="headers1"
													:items="applicationsQuota.items"
													:loading="false"
													:options.sync="options1"
													show-expand
													:footer-props="{
														'items-per-page-options': [5, 10, 20, 30, 40, 50],
													}"
												>
													<template v-slot:expanded-item="{ headers, item }">
														<td
															:colspan="headers.length"
															style="padding: 0; border-radius: 10px !important"
														>
															<div class="v-data-table">
																<div class="v-data-table__wrapper">
																	<table width="100%">
																		<thead class="v-data-table-header">
																			<tr>
																				<th
																					style="width: 120px; min-width: 120px; max-width: 120px"
																				></th>
																				<th style="width: 100%; min-width: 20vw">Рыбы</th>
																				<th
																					style="width: 150px; min-width: 150px; max-width: 150px"
																				>
																					Объем
																				</th>
																				<!--<th style="width: 150px; min-width: 150px; max-width: 150px;">Освоено квот</th>-->
																			</tr>
																		</thead>
																		<tbody>
																			<template v-if="fish.type == 'quota'">
																				<tr v-for="fish in item.item.values" :key="fish.fish_id">
																					<td></td>
																					<td>{{ fishList(fish.fish_id) }}</td>
																					<td>{{ Math.abs(fish.value) }}</td>
																					<!--<td>?</td>-->
																				</tr>
																			</template>
																		</tbody>
																	</table>
																</div>
															</div>
														</td>
													</template>

													<template v-slot:item.name="{ item }">
														<a href="#">
															{{ item.name }}
														</a>
													</template>
													<template v-slot:item.region="{ item }">
														{{ regionList(applicationsQuota.region_id) }}
													</template>
													<template v-slot:item.pond="{ item }">
														{{ pondList(item.pond_id) }}
													</template>
													<template v-slot:item.volume="{ item }">
														{{ totalVolumeQuota(item.item.values) }}
													</template>
													<template v-slot:item.quota="{ item }"> ? </template>

													<v-alert slot="no-results" :value="true" color="error">
														{{ $t('messages.search_not_have_resultes') }}
													</v-alert>
												</v-data-table>
											</div>
										</div>

										<div class="user-profile__tab--item" v-if="tabElement == 'tabElement2'">
											<div class="table__block--content user-profile__block--content">
												<div class="user-profile__block--update">
													<button
														class="user-profile--update"
														@click="permissionsIntegration"
														:disabled="permissionsIntegrateUpdate"
													>
														<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16.625 1.583v4.75h-4.75" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M2.375 9.5A7.125 7.125 0 0114.25 4.196l2.375 2.137M2.375 17.417v-4.75h4.75" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M16.625 9.5A7.126 7.126 0 014.75 14.804l-2.375-2.137" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
														{{ $t('commands.update') }}
													</button>
												</div>

												<v-data-table
													:headers="headers2"
													:items="permissionsIntegrateList"
													:loading="false"
													:options.sync="options2"
													:footer-props="{
														'items-per-page-options': [5, 10, 20, 30, 40, 50],
													}"
													v-if="permissionsIntegrateList"
												>
												</v-data-table>

												<div
													class="form__block--line form__messages"
													:class="{
														'form__messages--error': permissionsMessage.status != 200,
														'form__messages--success': permissionsMessage.status == 200,
													}"
													v-if="permissionsMessage.status"
												>
													<label class="form__block--input">
														<span class="form__block--title">
															{{ permissionsMessage.text }}
														</span>
													</label>
												</div>
											</div>
										</div>

										<div class="user-profile__tab--item" v-if="tabElement == 'tabElement3'">
											<div class="table__block--content user-profile__block--content">
												<div class="user-profile__tabs--children">
													<ul>
														<li v-bind:class="{ active: tabElementChildren == 'tabElement1' }">
															<a @click="tabElementChildren = 'tabElement1'">{{
																$t('sidebar.magazines_trade')
															}}</a>
														</li>
														<li
															v-bind:class="{
																active: tabElementChildren == 'tabElement2',
																disabled: true,
															}"
														>
															<a @click="tabElementChildren = 'tabElement2'">{{
																$t('sidebar.magazines_grown')
															}}</a>
														</li>
														<li
															v-bind:class="{
																active: tabElementChildren == 'tabElement3',
																disabled: true,
															}"
														>
															<a @click="tabElementChildren = 'tabElement3'"
																>{{ $t('sidebar.magazines_purchased') }}
															</a>
														</li>
														<li
															v-bind:class="{
																active: tabElementChildren == 'tabElement4',
																disabled: true,
															}"
														>
															<a @click="tabElementChildren = 'tabElement4'"
																>{{ $t('sidebar.magazines_implemented') }}
															</a>
														</li>
													</ul>
												</div>

												<div v-if="tabElementChildren == 'tabElement1'">
													<div class="user-profile__block--update">
														<button
															class="user-profile--update"
															@click="apiGetUserTrade"
															:disabled="tradeIntegrateUpdate"
														>
															<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16.625 1.583v4.75h-4.75" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M2.375 9.5A7.125 7.125 0 0114.25 4.196l2.375 2.137M2.375 17.417v-4.75h4.75" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M16.625 9.5A7.126 7.126 0 014.75 14.804l-2.375-2.137" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
															{{ $t('commands.update') }}
														</button>
													</div>

													<v-data-table
														:headers="getHeaders3"
														:items="applicationsTrade"
														:loading="false"
														:options.sync="options3"
														show-expand
														:server-items-length="totalTradeItems"
														:footer-props="{
															'items-per-page-options': [5, 10, 20, 30, 40, 50],
														}"
														@update:options="paginationUserTradeList"
														v-if="applicationsTrade"
													>
														<template v-slot:item.name="{ item }">
															<router-link :to="'/' + $i18n.locale + '/account/trade/' + item.id">
																{{
																	$t('labels.catch_at', [
																		new Date(item.catch_at).toLocaleDateString(),
																	])
																}}
															</router-link>
														</template>
														<template v-slot:item.brigade_id="{ item }">
															{{ getBrigadeName(item.brigade_id) }}
														</template>
														<template v-slot:item.status="{ item }">
															<a
																class="tb__table--status"
																:class="{
																	'tb__status--success': item.status == 'active',
																	'tb__status--error': item.status == 'deleted',
																}"
															>
																{{ $t('status.' + item.status) }}
															</a>
														</template>
														<template v-slot:item.fish="{ header, item }">
															{{ findFish(item.fishs, header.fish_id) }}
														</template>
													</v-data-table>
												</div>
												<div v-if="tabElementChildren == 'tabElement2'">
													<div class="user-profile__block--update">
														<button
															class="user-profile--update"
															@click="apiGetUserGrownTrade"
															:disabled="grownTradeUpdatee"
														>
															<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16.625 1.583v4.75h-4.75" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M2.375 9.5A7.125 7.125 0 0114.25 4.196l2.375 2.137M2.375 17.417v-4.75h4.75" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M16.625 9.5A7.126 7.126 0 014.75 14.804l-2.375-2.137" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
															{{ $t('commands.update') }}
														</button>
													</div>

													<v-data-table
														:headers="[
															{ text: 'Id', value: 'id', width: '50px' },
															{ text: $t('headers.name'), value: 'name', width: '15vw' },
															{ text: 'Номер справки о происхождении', value: 'reference' },
															{ text: 'Дата забоя', value: 'catch_at' },
															{ text: $t('headers.volume'), value: 'volume', width: '150px' },
														]"
														:items="applicationsGrownTrade"
														:loading="false"
														:options.sync="options3"
														show-expand
														:footer-props="{
															'items-per-page-options': [5, 10, 20, 30, 40, 50],
														}"
														v-if="applicationsGrownTrade"
													>
														<template v-slot:expanded-item="{ headers, item }">
															<td
																:colspan="headers.length"
																style="padding: 0; border-radius: 10px !important"
															>
																<div class="v-data-table">
																	<div class="v-data-table__wrapper">
																		<table width="100%">
																			<thead class="v-data-table-header">
																				<tr>
																					<th
																						style="width: 120px; min-width: 120px; max-width: 120px"
																					></th>
																					<th style="width: 100%; min-width: 20vw">Рыбы</th>
																					<th
																						style="width: 150px; min-width: 150px; max-width: 150px"
																					>
																						Объем
																					</th>
																				</tr>
																			</thead>
																			<tbody>
																				<tr v-for="fish in item.fishs" :key="fish.fish_id">
																					<td></td>
																					<td>{{ fishList(fish.fish_id) }}</td>
																					<td>{{ Math.abs(fish.value) }}</td>
																				</tr>
																			</tbody>
																		</table>
																	</div>
																</div>
															</td>
														</template>
														<template v-slot:item.name="{ item }">
															Улов от {{ item.catch_at | formatOnlyDate }}
														</template>
														<template v-slot:item.volume="{ item }">
															{{ totalFishVolume(item.fishs) }}
														</template>
														<template v-slot:item.catch_at="{ item }">
															{{ item.catch_at | formatDate }}
														</template>
														<v-alert slot="no-results" :value="true" color="error">
															Ваш поиск по запросу не дал результатов.
														</v-alert>
													</v-data-table>
													<div
														class="form__block--line form__messages"
														:class="{
															'form__messages--error': grownTradeMessage.status != 200,
															'form__messages--success': grownTradeMessage.status == 200,
														}"
														v-if="grownTradeMessage.status"
													>
														<label class="form__block--input">
															<span class="form__block--title">
																{{ grownTradeMessage.text }}
															</span>
														</label>
													</div>
												</div>
												<div v-if="tabElementChildren == 'tabElement3'">
													<div class="user-profile__block--update">
														<button
															class="user-profile--update"
															@click="apiGetUserPurchasedTrade"
															:disabled="purchasedTradeUpdatee"
														>
															<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16.625 1.583v4.75h-4.75" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M2.375 9.5A7.125 7.125 0 0114.25 4.196l2.375 2.137M2.375 17.417v-4.75h4.75" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M16.625 9.5A7.126 7.126 0 014.75 14.804l-2.375-2.137" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
															{{ $t('commands.update') }}
														</button>
													</div>

													<v-data-table
														:headers="[
															{ text: 'Id', value: 'id', width: '50px' },
															{ text: this.$t('headers.name'), value: 'name', width: '15vw' },
															{ text: 'Номер справки о происхождении', value: 'reference' },
															{ text: 'Дата закупки', value: 'catch_at' },
															{ text: this.$t('headers.volume'), value: 'volume', width: '150px' },
														]"
														:items="applicationsPurchasedTrade"
														:loading="false"
														:options.sync="options3"
														show-expand
														:footer-props="{
															'items-per-page-options': [5, 10, 20, 30, 40, 50],
														}"
														v-if="applicationsPurchasedTrade"
													>
														<template v-slot:expanded-item="{ headers, item }">
															<td
																:colspan="headers.length"
																style="padding: 0; border-radius: 10px !important"
															>
																<div class="v-data-table">
																	<div class="v-data-table__wrapper">
																		<table width="100%">
																			<thead class="v-data-table-header">
																				<tr>
																					<th
																						style="width: 120px; min-width: 120px; max-width: 120px"
																					></th>
																					<th style="width: 100%; min-width: 20vw">Рыбы</th>
																					<th
																						style="width: 150px; min-width: 150px; max-width: 150px"
																					>
																						Объем
																					</th>
																				</tr>
																			</thead>
																			<tbody>
																				<tr v-for="fish in item.fishs" :key="fish.fish_id">
																					<td></td>
																					<td>{{ fishList(fish.fish_id) }}</td>
																					<td>{{ Math.abs(fish.value) }}</td>
																				</tr>
																			</tbody>
																		</table>
																	</div>
																</div>
															</td>
														</template>
														<template v-slot:item.name="{ item }">
															Улов от {{ item.catch_at | formatOnlyDate }}
														</template>
														<template v-slot:item.volume="{ item }">
															{{ totalFishVolume(item.fishs) }}
														</template>
														<template v-slot:item.catch_at="{ item }">
															{{ item.catch_at | formatDate }}
														</template>
														<v-alert slot="no-results" :value="true" color="error">
															Ваш поиск по запросу не дал результатов.
														</v-alert>
													</v-data-table>
													<div
														class="form__block--line form__messages"
														:class="{
															'form__messages--error': purchasedTradeMessage.status != 200,
															'form__messages--success': purchasedTradeMessage.status == 200,
														}"
														v-if="purchasedTradeMessage.status"
													>
														<label class="form__block--input">
															<span class="form__block--title">
																{{ purchasedTradeMessage.text }}
															</span>
														</label>
													</div>
												</div>
												<div v-if="tabElementChildren == 'tabElement4'">
													<div class="user-profile__block--update">
														<button
															class="user-profile--update"
															@click="apiGetUserImplementedTrade"
															:disabled="implementedTradeUpdatee"
														>
															<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16.625 1.583v4.75h-4.75" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M2.375 9.5A7.125 7.125 0 0114.25 4.196l2.375 2.137M2.375 17.417v-4.75h4.75" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M16.625 9.5A7.126 7.126 0 014.75 14.804l-2.375-2.137" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
															{{ $t('commands.update') }}
														</button>
													</div>

													<v-data-table
														:headers="[
															{ text: 'Id', value: 'id', width: '50px' },
															{ text: $t('headers.name'), value: 'name', width: '15vw' },
															{ text: 'Номер справки о происхождении', value: 'reference' },
															{ text: 'Дата реализации', value: 'catch_at' },
															{ text: this.$t('headers.volume'), value: 'volume', width: '150px' },
														]"
														:items="applicationsImplementedTrade"
														:loading="false"
														:options.sync="options3"
														show-expand
														:footer-props="{
															'items-per-page-options': [5, 10, 20, 30, 40, 50],
														}"
														v-if="applicationsImplementedTrade"
													>
														<template v-slot:expanded-item="{ headers, item }">
															<td
																:colspan="headers.length"
																style="padding: 0; border-radius: 10px !important"
															>
																<div class="v-data-table">
																	<div class="v-data-table__wrapper">
																		<table width="100%">
																			<thead class="v-data-table-header">
																				<tr>
																					<th
																						style="width: 120px; min-width: 120px; max-width: 120px"
																					></th>
																					<th style="width: 100%; min-width: 20vw">Рыбы</th>
																					<th
																						style="width: 150px; min-width: 150px; max-width: 150px"
																					>
																						Объем
																					</th>
																				</tr>
																			</thead>
																			<tbody>
																				<tr v-for="fish in item.fishs" :key="fish.fish_id">
																					<td></td>
																					<td>{{ fishList(fish.fish_id) }}</td>
																					<td>{{ Math.abs(fish.value) }}</td>
																				</tr>
																			</tbody>
																		</table>
																	</div>
																</div>
															</td>
														</template>
														<template v-slot:item.name="{ item }">
															Улов от {{ item.catch_at | formatOnlyDate }}
														</template>
														<template v-slot:item.volume="{ item }">
															{{ totalFishVolume(item.fishs) }}
														</template>
														<template v-slot:item.catch_at="{ item }">
															{{ item.catch_at | formatDate }}
														</template>
														<v-alert slot="no-results" :value="true" color="error">
															Ваш поиск по запросу не дал результатов.
														</v-alert>
													</v-data-table>
													<div
														class="form__block--line form__messages"
														:class="{
															'form__messages--error': implementedTradeMessage.status != 200,
															'form__messages--success': implementedTradeMessage.status == 200,
														}"
														v-if="implementedTradeMessage.status"
													>
														<label class="form__block--input">
															<span class="form__block--title">
																{{ implementedTradeMessage.text }}
															</span>
														</label>
													</div>
												</div>
											</div>
										</div>

										<div class="user-profile__tab--item" v-if="tabElement == 'tabElement4'">
											<div class="table__block--content user-profile__block--content">
												<div class="user-profile__tabs--children">
													<ul>
														<li v-bind:class="{ active: tabElementChildre2 == 'tabElement1' }">
															<a @click="tabElementChildre2 = 'tabElement1'">О происхождении</a>
														</li>
														<li v-bind:class="{ active: tabElementChildre2 == 'tabElement2' }">
															<a @click="tabElementChildre2 = 'tabElement2'">Ветеринарные</a>
														</li>
														<li v-bind:class="{ active: tabElementChildre2 == 'tabElement3' }">
															<a @click="tabElementChildre2 = 'tabElement3'">Вет. сертификат</a>
														</li>
													</ul>
												</div>

												<div v-if="tabElementChildre2 == 'tabElement1'">
													<div class="user-profile__block--update">
														<button
															class="user-profile--update"
															@click="permissionsIntegration"
															:disabled="permissionsIntegrateUpdate"
														>
															<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16.625 1.583v4.75h-4.75" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M2.375 9.5A7.125 7.125 0 0114.25 4.196l2.375 2.137M2.375 17.417v-4.75h4.75" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M16.625 9.5A7.126 7.126 0 014.75 14.804l-2.375-2.137" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
															{{ $t('commands.update') }}
														</button>
													</div>

													<v-data-table
														:headers="[
															{ text: 'Название', value: 'ActivityType' },
															{ text: 'Номер', value: 'GlobalUniqueNumber' },
															{ text: 'Никад', value: 'Nikad' },
															{ text: this.$t('headers.status'), value: 'Status' },
															{ text: 'Дата выпуска', value: 'IssueDate', width: '100px' },
															{
																text: 'Результаты PDF',
																value: 'DocumentUrl',
																align: 'center',
																sortable: false,
																width: '150px',
															},
														]"
														:items="referenceOriginIntegrateList"
														:loading="false"
														:options.sync="options2"
														:footer-props="{
															'items-per-page-options': [5, 10, 20, 30, 40, 50],
														}"
														v-if="referenceOriginIntegrateList"
													>
														<template v-slot:item.ActivityType="{ item }">
															<span v-if="$i18n.locale == 'ru'">
																{{ item.ActivityType['NameRu'] }}
															</span>
															<span v-if="$i18n.locale == 'kz'">
																{{ item.ActivityType['NameKz'] }}
															</span>
															<span v-if="$i18n.locale == 'en'">
																{{ item.ActivityType['NameEn'] }}
															</span>
														</template>
														<template v-slot:item.GlobalUniqueNumber="{ item }">
															{{ item.GlobalUniqueNumber }}
														</template>
														<template v-slot:item.IssueDate="{ item }">
															{{ item.IssueDate | formatDate }}
														</template>
														<template v-slot:item.Status="{ item }">
															<span v-if="$i18n.locale == 'ru'">
																{{ item.Status['NameRu'] }}
															</span>
															<span v-if="$i18n.locale == 'kz'">
																{{ item.Status['NameKz'] }}
															</span>
															<span v-if="$i18n.locale == 'en'">
																{{ item.Status['NameRu'] }}
															</span>
														</template>
														<template v-slot:item.DocumentUrl="{ item }">
															<a
																:href="item.DocumentUrl"
																class="tb__table--btn tb__table--delete"
																target="_blank"
																v-tooltip.top-center="{
																	content: $t('button.download'),
																	class: ['tooltip__btn'],
																}"
															>
																<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M4.304 7.295v20.947c0 .967.79 1.758 1.758 1.758h17.984c.966 0 1.757-.791 1.757-1.758V1.758C25.803.79 25.013 0 24.046 0H11.599" fill="#E74C3C"/><path d="M4.304 7.295H9.84c.967 0 1.758-.791 1.758-1.758V0" fill="#C0392B"/><path d="M2.397 19.653h1.907V17.14l-2.079 2.589c.036-.046.095-.075.172-.075zM27.71 19.653h-1.907V17.14l2.079 2.589c-.036-.046-.095-.075-.172-.075z" fill="#262626"/><path d="M25.907 25.238a.456.456 0 01-.396.275H4.596a.456.456 0 01-.396-.275l-1.994-5.31c-.056-.151.03-.275.19-.275H27.71c.161 0 .246.124.19.275l-1.993 5.31z" fill="#C0392B"/><path d="M9.822 23.974c0-.147.043-.26.13-.34.087-.08.214-.12.38-.12.158 0 .28.04.364.12.084.08.127.194.127.34a.436.436 0 01-.13.337.51.51 0 01-.361.12.547.547 0 01-.374-.12.425.425 0 01-.136-.337zM13.952 21.984c0 .392-.115.694-.346.907-.23.213-.558.32-.983.32h-.265v1.16h-.946v-3.5h1.211c.443 0 .775.097.997.29.221.193.332.467.332.823zm-1.594.455h.172a.47.47 0 00.339-.12.435.435 0 00.126-.33c0-.236-.131-.354-.393-.354h-.244v.804zM17.482 22.547c0 .584-.16 1.035-.483 1.35-.321.317-.773.475-1.356.475H14.51V20.87h1.212c.562 0 .996.144 1.301.431.306.287.459.702.459 1.245zm-.982.034c0-.321-.063-.559-.19-.714-.127-.155-.32-.232-.578-.232h-.276v1.961h.21c.288 0 .499-.083.633-.25.134-.167.201-.422.201-.765zM19.04 24.372h-.93V20.87h2.068v.759h-1.137v.668h1.049v.76h-1.05v1.314zM21.386 10.835c0 .241-.263.44-.586.44H9.224c-.322 0-.586-.199-.586-.44 0-.242.264-.44.586-.44H20.8c.323 0 .586.198.586.44zM21.362 13.471c0 .242-.264.44-.586.44H9.2c-.323 0-.586-.198-.586-.44 0-.241.263-.44.586-.44h11.576c.322 0 .586.199.586.44zM21.386 16.114c0 .241-.263.44-.586.44H9.224c-.322 0-.586-.198-.586-.44s.264-.44.586-.44H20.8c.323 0 .586.198.586.44z" fill="#fff"/></svg>
															</a>
														</template>
														<v-alert slot="no-results" :value="true" color="error">
															Ваш поиск по запросу не дал результатов.
														</v-alert>
													</v-data-table>
												</div>
												<div v-if="tabElementChildre2 == 'tabElement2'">
													<div class="user-profile__block--update">
														<button
															class="user-profile--update"
															@click="permissionsIntegration"
															:disabled="permissionsIntegrateUpdate"
														>
															<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16.625 1.583v4.75h-4.75" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M2.375 9.5A7.125 7.125 0 0114.25 4.196l2.375 2.137M2.375 17.417v-4.75h4.75" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M16.625 9.5A7.126 7.126 0 014.75 14.804l-2.375-2.137" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
															{{ $t('commands.update') }}
														</button>
													</div>

													<v-data-table
														:headers="[
															{ text: 'Название', value: 'ActivityType' },
															{ text: 'Номер', value: 'GlobalUniqueNumber' },
															{ text: 'Никад', value: 'Nikad' },
															{ text: this.$t('headers.status'), value: 'Status' },
															{ text: 'Дата выпуска', value: 'IssueDate', width: '100px' },
															{
																text: 'Результаты PDF',
																value: 'DocumentUrl',
																align: 'center',
																sortable: false,
																width: '150px',
															},
														]"
														:items="referenceVeterinaryIntegrateList"
														:loading="false"
														:options.sync="options2"
														:footer-props="{
															'items-per-page-options': [5, 10, 20, 30, 40, 50],
														}"
														v-if="referenceVeterinaryIntegrateList"
													>
														<template v-slot:item.ActivityType="{ item }">
															<span v-if="$i18n.locale == 'ru'">
																{{ item.ActivityType['NameRu'] }}
															</span>
															<span v-if="$i18n.locale == 'kz'">
																{{ item.ActivityType['NameKz'] }}
															</span>
															<span v-if="$i18n.locale == 'en'">
																{{ item.ActivityType['NameEn'] }}
															</span>
														</template>
														<template v-slot:item.GlobalUniqueNumber="{ item }">
															{{ item.GlobalUniqueNumber }}
														</template>
														<template v-slot:item.IssueDate="{ item }">
															{{ item.IssueDate | formatDate }}
														</template>
														<template v-slot:item.Status="{ item }">
															<span v-if="$i18n.locale == 'ru'">
																{{ item.Status['NameRu'] }}
															</span>
															<span v-if="$i18n.locale == 'kz'">
																{{ item.Status['NameKz'] }}
															</span>
															<span v-if="$i18n.locale == 'en'">
																{{ item.Status['NameRu'] }}
															</span>
														</template>
														<template v-slot:item.DocumentUrl="{ item }">
															<a
																:href="item.DocumentUrl"
																class="tb__table--btn tb__table--delete"
																target="_blank"
																v-tooltip.top-center="{
																	content: $t('button.download'),
																	class: ['tooltip__btn'],
																}"
															>
																<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M4.304 7.295v20.947c0 .967.79 1.758 1.758 1.758h17.984c.966 0 1.757-.791 1.757-1.758V1.758C25.803.79 25.013 0 24.046 0H11.599" fill="#E74C3C"/><path d="M4.304 7.295H9.84c.967 0 1.758-.791 1.758-1.758V0" fill="#C0392B"/><path d="M2.397 19.653h1.907V17.14l-2.079 2.589c.036-.046.095-.075.172-.075zM27.71 19.653h-1.907V17.14l2.079 2.589c-.036-.046-.095-.075-.172-.075z" fill="#262626"/><path d="M25.907 25.238a.456.456 0 01-.396.275H4.596a.456.456 0 01-.396-.275l-1.994-5.31c-.056-.151.03-.275.19-.275H27.71c.161 0 .246.124.19.275l-1.993 5.31z" fill="#C0392B"/><path d="M9.822 23.974c0-.147.043-.26.13-.34.087-.08.214-.12.38-.12.158 0 .28.04.364.12.084.08.127.194.127.34a.436.436 0 01-.13.337.51.51 0 01-.361.12.547.547 0 01-.374-.12.425.425 0 01-.136-.337zM13.952 21.984c0 .392-.115.694-.346.907-.23.213-.558.32-.983.32h-.265v1.16h-.946v-3.5h1.211c.443 0 .775.097.997.29.221.193.332.467.332.823zm-1.594.455h.172a.47.47 0 00.339-.12.435.435 0 00.126-.33c0-.236-.131-.354-.393-.354h-.244v.804zM17.482 22.547c0 .584-.16 1.035-.483 1.35-.321.317-.773.475-1.356.475H14.51V20.87h1.212c.562 0 .996.144 1.301.431.306.287.459.702.459 1.245zm-.982.034c0-.321-.063-.559-.19-.714-.127-.155-.32-.232-.578-.232h-.276v1.961h.21c.288 0 .499-.083.633-.25.134-.167.201-.422.201-.765zM19.04 24.372h-.93V20.87h2.068v.759h-1.137v.668h1.049v.76h-1.05v1.314zM21.386 10.835c0 .241-.263.44-.586.44H9.224c-.322 0-.586-.199-.586-.44 0-.242.264-.44.586-.44H20.8c.323 0 .586.198.586.44zM21.362 13.471c0 .242-.264.44-.586.44H9.2c-.323 0-.586-.198-.586-.44 0-.241.263-.44.586-.44h11.576c.322 0 .586.199.586.44zM21.386 16.114c0 .241-.263.44-.586.44H9.224c-.322 0-.586-.198-.586-.44s.264-.44.586-.44H20.8c.323 0 .586.198.586.44z" fill="#fff"/></svg>
															</a>
														</template>
														<v-alert slot="no-results" :value="true" color="error">
															Ваш поиск по запросу не дал результатов.
														</v-alert>
													</v-data-table>
												</div>
												<div v-if="tabElementChildre2 == 'tabElement3'">
													<div class="user-profile__block--update">
														<button
															class="user-profile--update"
															@click="permissionsIntegration"
															:disabled="permissionsIntegrateUpdate"
														>
															<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16.625 1.583v4.75h-4.75" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M2.375 9.5A7.125 7.125 0 0114.25 4.196l2.375 2.137M2.375 17.417v-4.75h4.75" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M16.625 9.5A7.126 7.126 0 014.75 14.804l-2.375-2.137" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
															{{ $t('commands.update') }}
														</button>
													</div>

													<v-data-table
														:headers="[
															{ text: 'Название', value: 'ActivityType' },
															{ text: 'Номер', value: 'GlobalUniqueNumber' },
															{ text: 'Никад', value: 'Nikad' },
															{ text: this.$t('headers.status'), value: 'Status' },
															{ text: 'Дата выпуска', value: 'IssueDate', width: '100px' },
															{
																text: 'Результаты PDF',
																value: 'DocumentUrl',
																align: 'center',
																sortable: false,
																width: '150px',
															},
														]"
														:items="certificateVeterinaryIntegrateList"
														:loading="false"
														:options.sync="options2"
														:footer-props="{
															'items-per-page-options': [5, 10, 20, 30, 40, 50],
														}"
														v-if="certificateVeterinaryIntegrateList"
													>
														<template v-slot:item.ActivityType="{ item }">
															<span v-if="$i18n.locale == 'ru'">
																{{ item.ActivityType['NameRu'] }}
															</span>
															<span v-if="$i18n.locale == 'kz'">
																{{ item.ActivityType['NameKz'] }}
															</span>
															<span v-if="$i18n.locale == 'en'">
																{{ item.ActivityType['NameEn'] }}
															</span>
														</template>
														<template v-slot:item.GlobalUniqueNumber="{ item }">
															{{ item.GlobalUniqueNumber }}
														</template>
														<template v-slot:item.IssueDate="{ item }">
															{{ item.IssueDate | formatDate }}
														</template>
														<template v-slot:item.Status="{ item }">
															<span v-if="$i18n.locale == 'ru'">
																{{ item.Status['NameRu'] }}
															</span>
															<span v-if="$i18n.locale == 'kz'">
																{{ item.Status['NameKz'] }}
															</span>
															<span v-if="$i18n.locale == 'en'">
																{{ item.Status['NameRu'] }}
															</span>
														</template>
														<template v-slot:item.DocumentUrl="{ item }">
															<a
																:href="item.DocumentUrl"
																class="tb__table--btn tb__table--delete"
																target="_blank"
																v-tooltip.top-center="{
																	content: $t('button.download'),
																	class: ['tooltip__btn'],
																}"
															>
																<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M4.304 7.295v20.947c0 .967.79 1.758 1.758 1.758h17.984c.966 0 1.757-.791 1.757-1.758V1.758C25.803.79 25.013 0 24.046 0H11.599" fill="#E74C3C"/><path d="M4.304 7.295H9.84c.967 0 1.758-.791 1.758-1.758V0" fill="#C0392B"/><path d="M2.397 19.653h1.907V17.14l-2.079 2.589c.036-.046.095-.075.172-.075zM27.71 19.653h-1.907V17.14l2.079 2.589c-.036-.046-.095-.075-.172-.075z" fill="#262626"/><path d="M25.907 25.238a.456.456 0 01-.396.275H4.596a.456.456 0 01-.396-.275l-1.994-5.31c-.056-.151.03-.275.19-.275H27.71c.161 0 .246.124.19.275l-1.993 5.31z" fill="#C0392B"/><path d="M9.822 23.974c0-.147.043-.26.13-.34.087-.08.214-.12.38-.12.158 0 .28.04.364.12.084.08.127.194.127.34a.436.436 0 01-.13.337.51.51 0 01-.361.12.547.547 0 01-.374-.12.425.425 0 01-.136-.337zM13.952 21.984c0 .392-.115.694-.346.907-.23.213-.558.32-.983.32h-.265v1.16h-.946v-3.5h1.211c.443 0 .775.097.997.29.221.193.332.467.332.823zm-1.594.455h.172a.47.47 0 00.339-.12.435.435 0 00.126-.33c0-.236-.131-.354-.393-.354h-.244v.804zM17.482 22.547c0 .584-.16 1.035-.483 1.35-.321.317-.773.475-1.356.475H14.51V20.87h1.212c.562 0 .996.144 1.301.431.306.287.459.702.459 1.245zm-.982.034c0-.321-.063-.559-.19-.714-.127-.155-.32-.232-.578-.232h-.276v1.961h.21c.288 0 .499-.083.633-.25.134-.167.201-.422.201-.765zM19.04 24.372h-.93V20.87h2.068v.759h-1.137v.668h1.049v.76h-1.05v1.314zM21.386 10.835c0 .241-.263.44-.586.44H9.224c-.322 0-.586-.199-.586-.44 0-.242.264-.44.586-.44H20.8c.323 0 .586.198.586.44zM21.362 13.471c0 .242-.264.44-.586.44H9.2c-.323 0-.586-.198-.586-.44 0-.241.263-.44.586-.44h11.576c.322 0 .586.199.586.44zM21.386 16.114c0 .241-.263.44-.586.44H9.224c-.322 0-.586-.198-.586-.44s.264-.44.586-.44H20.8c.323 0 .586.198.586.44z" fill="#fff"/></svg>
															</a>
														</template>
														<v-alert slot="no-results" :value="true" color="error">
															Ваш поиск по запросу не дал результатов.
														</v-alert>
													</v-data-table>
												</div>

												<div
													class="form__block--line form__messages"
													:class="{
														'form__messages--error': permissionsMessage.status != 200,
														'form__messages--success': permissionsMessage.status == 200,
													}"
													v-if="permissionsMessage.status"
												>
													<label class="form__block--input">
														<span class="form__block--title">
															{{ permissionsMessage.text }}
														</span>
													</label>
												</div>
											</div>
										</div>

										<div class="user-profile__tab--item" v-if="tabElement == 'tabElement5'">
											<div class="table__block--content user-profile__block--content">
												<div class="user-profile__block--update">
													<button
														class="user-profile--update"
														@click="erapIntegration"
														:disabled="erapIntegrateUpdate"
													>
														<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16.625 1.583v4.75h-4.75" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M2.375 9.5A7.125 7.125 0 0114.25 4.196l2.375 2.137M2.375 17.417v-4.75h4.75" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M16.625 9.5A7.126 7.126 0 014.75 14.804l-2.375-2.137" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
														{{ $t('commands.update') }}
													</button>
												</div>

												<v-data-table
													:headers="[
														{ text: 'Правонарушение', value: 'qualification' },
														{ text: 'Организация', value: 'offenceorg' },
														{ text: 'Сумма штрафа', value: 'penaltysize' },
														{ text: 'Сумма платежа', value: 'paymentsize' },
														{ text: 'Дата', value: 'commissiondate', width: '150px' },
													]"
													:items="erapIntegrateList"
													:loading="false"
													:options.sync="options5"
													:footer-props="{
														'items-per-page-options': [5, 10, 20, 30, 40, 50],
													}"
													v-if="erapIntegrateList"
												>
													<template v-slot:item.qualification="{ item }">
														<strong>{{ item.qualification_code }}</strong> -
														{{ item.qualification }}
													</template>
													<template v-slot:item.commissiondate="{ item }">
														{{ item.commissiondate | formatDate }}
													</template>
													<v-alert slot="no-results" :value="true" color="error">
														{{ $t('messages.search_not_have_resultes') }}
													</v-alert>
												</v-data-table>

												<div
													class="form__block--line form__messages"
													:class="{
														'form__messages--error': erapMessage.status != 200,
														'form__messages--success': erapMessage.status == 200,
													}"
													v-if="erapMessage.status"
												>
													<label class="form__block--input">
														<span class="form__block--title">
															{{ erapMessage.text }}
														</span>
													</label>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { api, urlApi, apiSecond } from '@/boot/axios'

import { MOCK_QUOTO, PERMISSIONS_DOC } from '@/mockData.js'

export default {
	data() {
		return {
			applicationsUser: null,
			//applicationPermission: null,
			applicationReference: null,
			applicationsQuota: null,
			applicationsPond: null,
			applicationsRegion: null,
			applicationsFish: null,

			applicationsTrade: null,
			tradeIntegrateUpdate: true,
			tradeMessage: {
				status: null,
				text: null,
			},

			applicationsGrownTrade: null,
			grownTradeUpdatee: true,
			grownTradeMessage: {
				status: null,
				text: null,
			},

			applicationsPurchasedTrade: null,
			purchasedTradeUpdatee: true,
			purchasedTradeMessage: {
				status: null,
				text: null,
			},

			applicationsImplementedTrade: null,
			implementedTradeUpdatee: true,
			implementedTradeMessage: {
				status: null,
				text: null,
			},

			tabElement: 'tabElement2',
			tabElementChildren: 'tabElement1',
			tabElementChildre2: 'tabElement1',

			urlApi: urlApi,

			errorMessage: {
				status: null,
				messages: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
			},

			headers1: [
				// { text: 'Id', value: 'id', width: '50px' },
				// { text: this.$t('headers.name'), value: 'name', width: '15vw' },
				// { text: this.$t('headers.regions'), value: 'region' },
				// { text: this.$t('placeholder.pond'), value: 'pond' },
				// { text: this.$t('headers.volume'), value: 'volume', width: '150px' },
				// { text: 'Освоено квот', value: 'quota', width: '150px'},
				// { text: 'Дата контракта', value: 'contractDate', width: '10vw' },
			],
			options1: {
				itemsPerPage: 5,
				page: 1,
			},
			headers2: [
				{ text: 'Id', value: 'id', width: '50px' },
				{ text: this.$t('headers.fish_plan_region'), value: 'fishingRegionPlan', width: '20vw' },
				{ text: this.$t('headers.spond_name'), value: 'PondName', width: '15vw' },
				{ text: this.$t('headers.fishers'), value: 'fishingPersons', width: '10vw' },
				{ text: this.$t('headers.fishing_type'), value: 'fishingType', width: '10vw' },
				// { text: 'Название', value: 'ActivityType' },
				// { text: 'Номер', value: 'GlobalUniqueNumber' },
				// { text: 'Никад', value: 'Nikad' },
				// { text: this.$t('headers.status'), value: 'Status' },
				// { text: 'Дата выпуска', value: 'IssueDate', width: '100px' },
				// {
				//   text: 'Результаты PDF',
				//   value: 'DocumentUrl',
				//   align: 'center',
				//   sortable: false,
				//   width: '150px',
				// },
			],

			headers3: [
				{ text: 'Id', value: 'id', width: '20px' },
				// {
				//   text: this.$t('headers.action'),
				//   value: 'action',
				//   align: 'center',
				//   sortable: false,
				//   width: '100px',
				// },
				{ text: this.$t('labels.catch'), value: 'name', width: '10vw' },
				{ text: this.$t('labels.brigade'), value: 'brigade_id' },
				{ text: this.$t('headers.status'), value: 'status' },
			],
			options3: {
				itemsPerPage: 5,
				page: 1,
			},
			totalTradeItems: 0,
			headers4: [
				{ text: 'Id', value: 'id', width: '50px' },
				{ text: this.$t('headers.number'), value: 'number' },
				{ text: this.$t('headers.name'), value: 'name', width: '30vw' },
				{ text: this.$t('headers.date_receiving'), value: 'date_at', width: '150px' },
				{
					text: this.$t('headers.result_pdf'),
					value: 'link',
					align: 'center',
					sortable: false,
					width: '150px',
				},
			],
			headers5: [
				{ text: this.$t('headers.offense'), value: 'qualification' },
				{ text: this.$t('headers.detecter_offense'), value: 'offenceorg' },
				{ text: this.$t('headers.fine_amount'), value: 'penaltysize' },
				{ text: this.$t('headers.amount_payment'), value: 'paymentsize' },
				{ text: this.$t('headers.date'), value: 'protocoldate', width: '150px' },
			],
			options4: {
				itemsPerPage: 5,
				page: 1,
			},

			referenceIntegrateUpdate: false,

			referenceOriginIntegrateList: null,
			referenceVeterinaryIntegrateList: null,
			certificateVeterinaryIntegrateList: null,

			permissionsIntegrateData: null,
			permissionsIntegrateList: null,
			permissionsIntegrateUpdate: true,
			permissionsMessage: {
				status: null,
				text: null,
			},
			options2: {
				itemsPerPage: 5,
				page: 1,
			},

			erapMessage: {
				status: null,
				text: null,
			},
			erapIntegrateUpdate: false,
			erapIntegrateData: null,
			erapIntegrateList: null,
			options5: {
				itemsPerPage: 10,
				page: 1,
			},
		}
	},
	computed: {
		...mapGetters(['_getFishes', '_getBrigades']),
		getBrigadeName() {
			return (id) => {
				return this._getBrigades.find((b) => b.id == id)?.title || '-'
			}
		},
		getHeaders3() {
			return [
				...this.headers3,
				...this._getFishes
					.filter((fish) => {
						return this.applicationsTrade.some((item) => {
							return item.fishs.some((f) => {
								return f.fish_id === fish.id
							})
						})
					})
					.map((fish) => {
						return { text: fish.name, value: 'fish', fish_id: fish.id, width: '150px' }
					}),
			]
		},
	},
	methods: {
		...mapActions(['_fetchFishes', '_fetchBrigades']),
		findFish(fishs, id) {
			return fishs.find((fish) => fish.fish_id == id)?.value || '0'
		},
		regionList(id) {
			let name = ''
			this.applicationsRegion.find((element) => {
				if (element.id == id) name = element.name
			})
			return name
		},
		pondList(id) {
			let name = ''
			this.applicationsPond.find((element) => {
				if (element.id == id) name = element.name
			})
			return name
		},
		fishList(id) {
			let name = ''
			this.applicationsFish.find((element) => {
				if (element.id == id) name = element.name
			})
			return name
		},
		totalFishVolume(value) {
			let total = 0
			value.find((element) => {
				total += Number(element.value)
			})
			return Math.abs(total)
		},
		totalVolume(value) {
			let total = 0
			value.find((element) => {
				total += Number(element.item.value)
			})
			return Math.abs(total)
		},
		totalVolumeQuota(value) {
			let total = 0
			value.find((element) => {
				if (element.type == 'quota') {
					total += Number(element.value)
				}
			})
			return Math.abs(total)
		},

		async apiGetUser() {
			try {
				const response = await api.get('/user-info', {
					params: {
						user_id: this.$store.state.userObject?.id,
					},
				})
				if (response.data) {
					this.applicationsUser = response.data
					this.applicationsUser.user_info = eval(
						'({obj:' + JSON.parse(this.applicationsUser.user_info) + '})',
					)
				}
			} catch (error) {
				if (error.response) {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					if (error?.response?.status == 403) {
						this.errorMessage.status = 403
						this.errorMessage.text = error?.response?.data?.message
					}
				}
			}
		},
		apiGetUserTrade() {
			this.tradeIntegrateUpdate = false

			this.apiGetUserTradeList()
		},
		paginationUserTradeList(options) {
			this.apiGetUserTradeList(options.page, options.itemsPerPage)
		},
		apiGetUserTradeList(page = 1, itemsPerPage = 10) {
			let queries = `user_id=${this.applicationsUser.id}&page=${page}&per_page=${itemsPerPage}`

			api
				.get(`trade/list?${queries}`)
				.then((response) => {
					if (response.data) {
						this.applicationsTrade = response.data.data
						this.totalTradeItems = response.data.total
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					if (error?.response?.status == 403) {
						this.errorMessage.status = 403
						this.errorMessage.text = error?.response?.data?.message
					}
				})
		},
		apiGetUserGrownTrade() {
			this.grownTradeUpdatee = false
			this.applicationsGrownTrade = []
		},
		apiGetUserPurchasedTrade() {
			this.purchasedTradeUpdatee = false
			this.applicationsPurchasedTrade = []
		},
		apiGetUserImplementedTrade() {
			this.implementedTradeUpdatee = false
			this.applicationsImplementedTrade = []
		},

		apiGetUserQuota() {
			this.applicationsQuota = MOCK_QUOTO
		},

		apiGetPondList() {
			this.applicationsPond = []
		},
		apiGetRegionList() {
			this.applicationsRegion = []
		},
		apiGetPondFish() {
			this.applicationsFish = []
		},

		async apiGetList() {
			await this.apiGetUser()
			//this.apiGetUserPermission();
			// this.apiGetUserReference();

			this.apiGetUserTrade()
			this.apiGetUserGrownTrade()
			this.apiGetUserPurchasedTrade()
			this.apiGetUserImplementedTrade()

			this.apiGetUserQuota()

			//Integration
			this.erapIntegration()
			this.permissionsIntegration()
		},

		//Integration
		async erapIntegration() {
			this.erapIntegrateData = null
			this.erapIntegrateList = null

			this.erapIntegrateUpdate = true

			const response = await apiSecond.post('/v2/sysErap/execute')

			if (response?.data?.response?.responseInfo?.status?.code == 200) {
				this.erapIntegrateList = response?.data?.response?.responseData?.data?.items?.item || []
			}

			this.erapMessage = {
				status: null,
				text: null,
			}
			this.erapIntegrateData = null
		},
		ersopRequestIntegration() {
			this.ersopMessage = {
				status: null,
				text: null,
			}
			api
				.get('integration/user/' + this.$store.state.userObject?.id + '/ersop')
				.then(() => {})
				.catch((error) => {
					if (error.response) {
						if (error?.response?.status == 500) {
							this.ersopMessage.status = 500
							this.ersopMessage.text = this.$t('system_message.500')
						} else if (error?.response?.status == 401) {
							this.ersopMessage.status = 401
							this.ersopMessage.text = error.response.data.error_message
							this.$router.push('/' + this.$i18n.locale + '/login')
						} else if (error?.response?.status == 404) {
							this.ersopMessage.status = error?.response?.status
							this.ersopMessage.text = this.$t('system_message.500')
						} else {
							this.ersopMessage.status = error?.response?.status
							this.ersopMessage.text = error.response.data.error_message
						}
					}
					this.ersopIntegrateUpdate = false
				})
			this.ersopIntegration()
		},
		ersopIntegration() {
			this.ersopIntegrateData = null
			this.ersopIntegrateList = null

			this.ersopIntegrateUpdate = true

			this.ersopMessage = {
				status: null,
				text: null,
			}

			//api.get('integration/user/'+this.$store.state.userObject?.id+'/ersop')
			api
				.post('integrations/shep/ersop-get', {
					user_id: this.$store.state.userObject?.id,
				})
				.then((response) => {
					if (response.status == 200) {
						this.ersopIntegrateData = response.data.data
						this.ersopIntegrateList = response.data.data

						/*if (this.ersopIntegrateData.response
              && this.ersopIntegrateData.response.responseData
              && this.ersopIntegrateData.response.responseData.data
              && this.ersopIntegrateData.response.responseData.data.items
          ) {
            this.ersopIntegrateList
                = this.ersopIntegrateData.response.responseData.data.items.item.slice().reverse();
          }*/
						this.ersopIntegrateUpdate = false
					}
				})
				.catch((error) => {
					if (error.response) {
						if (error?.response?.status == 500) {
							this.ersopMessage.status = 500
							this.ersopMessage.text = this.$t('system_message.500')
						} else if (error?.response?.status == 401) {
							this.ersopMessage.status = 401
							this.ersopMessage.text = error.response.data.error_message
							this.$router.push('/' + this.$i18n.locale + '/login')
						} else if (error?.response?.status == 404) {
							this.ersopMessage.status = error?.response?.status
							this.ersopMessage.text = this.$t('system_message.500')
						} else {
							this.ersopMessage.status = error?.response?.status
							this.ersopMessage.text = error.response.data.error_message
						}
					}
					this.ersopIntegrateUpdate = false
				})
		},
		async permissionsIntegration() {
			this.permissionsIntegrateData = null
			this.permissionsIntegrateList = null
			this.referenceOriginIntegrateList = null
			this.referenceVeterinaryIntegrateList = null
			this.certificateVeterinaryIntegrateList = null

			this.permissionsIntegrateUpdate = true

			this.permissionsMessage = {
				status: null,
				text: null,
			}

			this.permissionsIntegrateData = PERMISSIONS_DOC

			const response = await apiSecond.get('/v2/r13-moosr3-services?filter[nullable]=false')
			if (response.data.data) {
				this.permissionsIntegrateList = response.data.data || []
			}

			// if (
			//   this.permissionsIntegrateData.response &&
			//   this.permissionsIntegrateData.response.responseData &&
			//   this.permissionsIntegrateData.response.responseData.data &&
			//   this.permissionsIntegrateData.response.responseData.data.Licenses
			// ) {
			//   this.permissionsIntegrateList =
			//     this.permissionsIntegrateData.response.responseData.data.Licenses.UniversalLicense?.slice().reverse();

			//   this.referenceOriginIntegrateList = this.permissionsIntegrateList;
			//   this.referenceVeterinaryIntegrateList = this.permissionsIntegrateList;
			//   this.certificateVeterinaryIntegrateList = this.permissionsIntegrateList;
			// }

			this.permissionsIntegrateUpdate = false
		},
	},
	async mounted() {
		await this._fetchFishes()
		await this._fetchBrigades()
		this.apiGetList()
		this.apiGetPondList()
		this.apiGetRegionList()
		this.apiGetPondFish()
	},
	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
}
</script>

<style scoped>
.disabled {
	pointer-events: none;
	opacity: 0.5;
}
</style>
